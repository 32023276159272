import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import bgimage from '../research01.png';
import { ACCOUNTS } from "./Constants";


const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
const mobileRegex = /^\+\d{1,4}\d{10}$/;


const Register = () => {
	const navigate = useNavigate();

	const [first_name, setFirstName] = useState('');
	const [firstNameError, setFirstNameError] = useState('');

	const [last_name, setLastName] = useState('');
	const [lastNameError, setLastNameError] = useState('');

	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');

	const [mobile, setMobile] = useState('');
	const [mobileError, setMobileError] = useState('');

	const [telephone, setTelephone] = useState('');
	const [telephoneError, setTelephoneError] = useState('');

	const [address, setAddress] = useState('');
	const [addressError, setAddressError] = useState('');

	const [is_staff, setIsStaff] = useState(false);

	const [is_reviewer, setIsReviewer] = useState(false);

	const [is_researcher, setIsResearcher] = useState(false);

	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');

	const [confirm_password, setConfirmPassword] = useState('');
	const [confirmPasswordError, setConfirmPasswordError] = useState('');

	const [isRegistered, setIsRegistered] = useState(false);

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
		setEmailError('');
	};

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
		setPasswordError('');
	};

	const handleConfirmPasswordChange = (event) => {
		setConfirmPassword(event.target.value);
		setConfirmPasswordError('');
	};

	const handleFirstNameChange = (event) => {
		setFirstName(event.target.value);
		setFirstNameError('');
	};

	const handleLastNameChange = (event) => {
		setLastName(event.target.value);
		setLastNameError('');
	};

	const handleMobileChange = (event) => {
		setMobile(event.target.value);
		setMobileError('');
	};

	const handleTelephoneChange = (event) => {
		setTelephone(event.target.value);
		setTelephoneError('');
	};

	const handleAddressChange = (event) => {
		setAddress(event.target.value);
		setAddressError('')
	};

	const handleRegistration = async (event) => {
		event.preventDefault();

		if (first_name.trim() === '') {
			setFirstNameError('Please enter your first name.');
			return;
		}

		if (last_name.trim() === '') {
			setLastNameError('Please enter your last name.');
			return;
		}

		if (email.trim() === '') {
			setEmailError('Please enter your email address.');
			return;
		}

		if (mobile.trim() === '') {
			setMobileError('Please enter your mobile number.');
			return;
		}

		if (!mobileRegex.test(mobile)) {
			setMobileError('Please enter valid mobile number.');
			return;
		}

		if (telephone.trim() !== '' && !mobileRegex.test(telephone)) {
			setTelephoneError('Please enter valid telephone number.');
			return;
		}

		if (address.trim() === '') {
			setAddressError('Please enter your physical address.');
			return;
		}

		if (password.trim() === '' || password.length < 8 || !passwordRegex.test(password)) {
			setPasswordError('Please enter a password with at least 8 characters, an alphabetic character and a digit');
			return;
		}

		if (confirm_password !== password) {
			setConfirmPasswordError('Password does not match. Enter password again here.');
			return;
		}

		console.log({
			first_name,
			last_name,
			email,
			mobile,
			telephone,
			address,
			is_staff,
			is_reviewer,
			is_researcher,
			password
		})

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				first_name,
				last_name,
				email,
				mobile,
				telephone,
				address,
				is_staff,
				is_reviewer,
				is_researcher,
				password
			})
		};

		try {
			fetch(ACCOUNTS, requestOptions)
				.then(response => {
					if (response.status === 201) {
						alert("New account successfully registered");
						setIsRegistered(true);
						navigate("/");
					} else {
						alert("Registration failed");
						// Handle other response statuses if needed
					}

					return response.json();
				})
				.then(data => {
					console.log(data);
				})
				.catch(error => console.log(error))
		} catch (error) {
			console.error(error);
		}

	};

	return (
		<section className="bg-image" style={{ backgroundImage: `url(${bgimage})` }}>
			<div className="mask d-flex align-items-center h-100 gradient-custom-3">
				<div className="container h-100">
					<div className="row d-flex justify-content-center align-items-center h-100">
						<div className="col-12 col-md-9 col-lg-7 col-xl-6">
							<h5 className='bg-primary text-center text-white m-0 py-4 rounded-top-1 mt-3'>Medical Research Council of Zimbabwe</h5>
							<div className="position-relative">
								<div className="card rounded-1 rounded-top-0">
									<div className="card-body p-5">
										<h2 className="text-center mb-5">Create an Account</h2>
										<form onSubmit={handleRegistration}>
											<div className="form-outline mb-4">
												<input
													type="text"
													name='first_name'
													className={`form-control form-control-lg rounded-1 ${firstNameError !== '' ? 'is-invalid' : ''}`}
													placeholder='First Name(s) (*)'
													onChange={handleFirstNameChange}
													value={first_name}
												/>
												{firstNameError && <div className="invalid-feedback">{firstNameError}</div>}
											</div>
											<div className="form-outline mb-4">
												<input
													type="text"
													name='last_name'
													className={`form-control form-control-lg rounded-1 ${lastNameError !== '' ? 'is-invalid' : ''}`}
													placeholder='Last Name (*)'
													onChange={handleLastNameChange}
													value={last_name}
												/>
												{lastNameError && <div className="invalid-feedback">{lastNameError}</div>}
											</div>
											<div className="form-outline mb-4">
												<input
													type="email"
													name='email'
													className={`form-control form-control-lg rounded-1 ${emailError !== '' ? 'is-invalid' : ''}`}
													placeholder='Email Address (*)'
													onChange={handleEmailChange}
													value={email}
												/>
												{emailError && <div className="invalid-feedback">{emailError}</div>}
												{/* <p class="text-info col-md-12 mb-0" >
											{errors.email && <div className="invalid-feedback">{errors.email}</div>}
										</p> */}
											</div>
											<div className="form-outline mb-4">
												<input
													type="text"
													name='mobile'
													className={`form-control form-control-lg rounded-1 ${mobileError !== '' ? 'is-invalid' : ''}`}
													placeholder='Mobile Number (*)'
													onChange={handleMobileChange}
													value={mobile}
												/>
												{mobileError && <div className="invalid-feedback">{mobileError}</div>}
												<p className="text-info col-md-12 mb-0" >
													<small id="phoneDesc">
														- Include country code (eg +263)
													</small>
												</p>
											</div>
											<div className="form-outline mb-4">
												<input
													type="text"
													name='telephone'
													className={`form-control form-control-lg rounded-1 ${telephoneError !== '' ? 'is-invalid' : ''}`}
													placeholder='Telephone Number'
													onChange={handleTelephoneChange}
													value={telephone}
												/>
												{telephoneError && <div className="invalid-feedback">{telephoneError}</div>}
												<p className="text-info col-md-12 mb-0" >
													<small id="phoneDesc">
														- Include country code (eg +263)
													</small>
												</p>
											</div>
											<div className="form-outline mb-4">
												<input
													type="text"
													name='physical_address'
													className={`form-control form-control-lg rounded-1 ${addressError !== '' ? 'is-invalid' : ''}`}
													placeholder='Physical Address'
													onChange={handleAddressChange}
													value={address}
												/>
												{addressError && <div className="invalid-feedback">{addressError}</div>}
											</div>

											<div className="form-outline mb-4">
												<label className="fs-5 my-2">Select Your Role</label>
												<div className="form-check">
													<input className="form-check-input" type="radio" name="usertype" id="staff" checked={is_staff} onChange={() => setIsStaff(!is_staff)} required />
													<label className="form-check-label" htmlFor="staff">
														Compliance
													</label>
												</div>
												<div className="form-check">
													<input className="form-check-input" type="radio" name="usertype" id="reviewer" checked={is_reviewer} onChange={() => setIsReviewer(!is_reviewer)} required />
													<label className="form-check-label" htmlFor="reviewer">
														Reviewer
													</label>
												</div>
												<div className="form-check">
													<input className="form-check-input" type="radio" name="usertype" id="researcher" checked={is_researcher} onChange={() => setIsResearcher(!is_researcher)} required />
													<label className="form-check-label" htmlFor="researcher">
														Researcher
													</label>
												</div>
											</div>



											<div className="form-outline mb-4">
												<input
													type="password"
													name='password'
													className={`form-control form-control-lg rounded-1 ${passwordError !== '' ? 'is-invalid' : ''}`}
													placeholder='Password (*)'
													onChange={handlePasswordChange}
													value={password}
												/>
												{passwordError && <div className="invalid-feedback">{passwordError}</div>}
												<p className="text-info col-md-12 mb-0" >
													<small id="passwordLen">- Minimum of 8 characters</small><br />
													<small id="passwordRegex">- Include letters and numbers</small>
												</p>
											</div>
											<div className="form-outline mb-4">
												<input
													type="password"
													name="confirmPassword"
													className={`form-control form-control-lg rounded-1 ${confirmPasswordError !== '' ? 'is-invalid' : ''}`}
													placeholder='Confirm Password (*)'
													value={confirm_password}
													onChange={handleConfirmPasswordChange}
												/>
												{confirmPasswordError && <div className="invalid-feedback">{confirmPasswordError}</div>}
											</div>
											<div className="d-flex justify-content-center">
												<button type="submit" className="btn btn-primary btn-lg gradient-custom-4 text-white w-100 rounded-1">
													<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
														<path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
													</svg>
													Register
												</button>
											</div>
											<p className="text-center text-muted mt-5 mb-0">Have already an account? <Link to="/" className="fw-bold text-body"><u>Login here</u></Link></p>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Register;