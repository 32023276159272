import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import Protocols from './Protocols';
import PendingProtocols from './PendingProtocols';
import AssignedProtocols from './AssignedProtocols';
import DeclinedProtocols from './DeclinedProtocols';
import ReviewedProtocols from './ReviewedProtocols';
import AcceptedProtocols from './AcceptedProtocols';
import RejectedProtocols from './RejectedProtocols';
import RespondedProtocols from './RespondedProtocols';
import {
  ACCOUNTS,
  PROTOCOLS,
  REFRESH_TOKEN
} from '../Constants';


const Dashboard = () => {
  const { state, checkAuthenticated, handleLogout } = useContext(AuthContext);
  const [isTokenRefreshing, setIsTokenRefreshing] = useState(false);
  const [protocols, setProtocols] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [message, setMessage] = useState("");
  const location = useLocation();


  // Check for authentication when the component mounts
  useEffect(() => {
    checkAuthenticated();
    getProtocols();
    getReviewers();
  }, []);

  // Get protocol
  const getProtocol = async (id) => {
    const response = await fetch(`${PROTOCOLS}/${id}/`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
    });

    if (response.status === 401 || response.status === 403) {
      // If the server responds with a 401 (Unauthorized) status code,
      // the access token has expired, so we need to refresh the token.
      setIsTokenRefreshing(true);

      // Send a request to the server to refresh the access token
      const refreshResponse = await fetch(REFRESH_TOKEN, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          refresh: localStorage.getItem('refresh')
        })
      });

      if (refreshResponse.ok) {
        // If the refresh request is successful, update the access token and
        // retry the original request with the new access token.
        const tokens = await refreshResponse.json();
        localStorage.setItem('access', tokens.access);
        setIsTokenRefreshing(false);
        return getProtocol(id);
      } else {
        // If the refresh request fails, the user needs to log in again.
        setIsTokenRefreshing(false);
        handleLogout();
      }
    } else {
      const data = await response.json();
      console.log(data);
      return data;
    }
  };

  // Get all study protocols
  const getProtocols = async () => {
    fetch(PROTOCOLS, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
    })
      .then(response => response.json())
      .then(data => setProtocols(data))
      .catch(error => console.error(error));
  };

  // Delete Protocol
  const deleteProtocol = async (protocol) => {
    const response = await fetch(`${PROTOCOLS}/${protocol.id}`, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
    })

    if (response.status === 401 || response.status === 403) {
      // Send a request to the server to refresh the access token
      const refreshResponse = await fetch(REFRESH_TOKEN, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          refresh: localStorage.getItem('refresh')
        })
      });

      if (refreshResponse.ok) {
        // If the refresh request is successful, update the access token and
        // retry the original request with the new access token.
        const tokens = await refreshResponse.json();
        localStorage.setItem('access', tokens.access);
        return deleteProtocol(protocol);
      } else {
        // If the refresh request fails, the user needs to log in again.
        handleLogout();
      }
    } else {
      // If the request succeeds, return the response data.
      if (response.status === 204) {
        alert("Submission successfully deleted.");
      } else {
        alert("Submission unsuccessfully deleted. Please try again.");
      }

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  // Get all reviewers
  const getReviewers = async () => {
    fetch(ACCOUNTS, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
    })
      .then(response => response.json())
      .then(data => setReviewers(data))
      .catch(error => console.error(error));
  };


  // Assign Reviewer(s)
  const assignReviewers = async (id, reviewers) => {
    const protocol = await getProtocol(id);
    const assigned_protocol = { ...protocol, ...reviewers }
    console.log(assigned_protocol);
    // Send a request with the current access token
    const response = await fetch(`${PROTOCOLS}/${id}/`, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
      body: JSON.stringify(assigned_protocol)
    });

    // If the request succeeds, return the response data.
    if (response.status === 200) {
      // Request was successful
      alert("Reviewer(s) successfully assigned.");
    } else {
      // Request failed
      alert('Reviewer(s) unsuccessfully assigned. Please try again.');
    }

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };




  return (
    <>
      <h1 className="mt-4">Dashboard <i className="bi bi-chevron-right"></i></h1>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="row">
              <div className="col-2 d-flex align-items-center justify-content-center">
                <button type="button" className="btn btn-outline-dark h-100 w-300 d-flex align-items-center justify-content-center">
                  <i className="fab fa-gitter font-20px"></i>
                </button>
              </div>
              <div className="col-10 d-flex align-items-center">
                <span className="text-dark fw-medium">Total Submissions<p className="font-weight-bold">{protocols.length}</p></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="row">
              <div className="col-2 d-flex align-items-center justify-content-center">
                <button type="button" className="btn btn-outline-warning h-100 w-300 d-flex align-items-center justify-content-center">
                  <i className="fab fa-gitter font-20px"></i>
                </button>
              </div>
              <div className="col-10 d-flex align-items-center">
                <span className="text-warning fw-medium">Pending <p className="font-weight-bold">{protocols.filter(protocol => protocol.status === "pending").length}</p></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="row">
              <div className="col-2 d-flex align-items-center justify-content-center">
                <button type="button" className="btn btn-outline-secondary h-100 w-300 d-flex align-items-center justify-content-center">
                  <i className="fab fa-gitter font-20px"></i>
                </button>
              </div>
              <div className="col-10 d-flex align-items-center">
                <span className="text-secondary fw-medium">Assigned <p className="font-weight-bold">{protocols.filter(protocol => protocol.status === "assigned").length}</p></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="row">
              <div className="col-2 d-flex align-items-center justify-content-center">
                <button type="button" className="btn btn-outline-primary h-100 w-300 d-flex align-items-center justify-content-center">
                  <i className="fab fa-gitter font-20px"></i>
                </button>
              </div>
              <div className="col-10 d-flex align-items-center">
                <span className="text-primary fw-medium">Reviewed <p className="font-weight-bold">{protocols.filter(protocol => protocol.status === "reviewed" || protocol.status === "partially reviewed").length}</p></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="row">
              <div className="col-2 d-flex align-items-center justify-content-center">
                <button type="button" className="btn btn-outline-success h-100 w-300 d-flex align-items-center justify-content-center">
                  <i className="fab fa-gitter font-20px"></i>
                </button>
              </div>
              <div className="col-10 d-flex align-items-center">
                <span className="text-success fw-medium">Accepted <p className="font-weight-bold">{protocols.filter(protocol => protocol.status === "accepted").length}</p></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="row">
              <div className="col-2 d-flex align-items-center justify-content-center">
                <button type="button" className="btn btn-outline-danger h-100 w-300 d-flex align-items-center justify-content-center">
                  <i className="fab fa-gitter font-20px"></i>
                </button>
              </div>
              <div className="col-10 d-flex align-items-center">
                <span className="text-danger fw-medium">Rejected <p className="font-weight-bold">{protocols.filter(protocol => protocol.status === "rejected").length}</p></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="row">
              <div className="col-2 d-flex align-items-center justify-content-center">
                <button type="button" className="btn btn-outline-dark h-100 w-300 d-flex align-items-center justify-content-center">
                  <i className="fab fa-gitter font-20px"></i>
                </button>
              </div>
              <div className="col-10 d-flex align-items-center">
                <span className="text-dark fw-medium">Responded <p className="font-weight-bold">{protocols.filter(protocol => protocol.status === "response").length}</p></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        (() => {
          switch (location.pathname) {
            case '/':
              return protocols.length > 0 ? <Protocols protocols={protocols} reviewers={reviewers} handleAssignReviewers={assignReviewers} message={message} onDelete={deleteProtocol} /> : <h4>No submissions to display</h4>;
            case '/new-submissions':
              return protocols?.filter(protocol => protocol.status === "pending").length > 0 ? <PendingProtocols protocols={protocols.filter(protocol => protocol.status === "pending")} reviewers={reviewers} handleAssignReviewers={assignReviewers} message={message} /> : <h4>No pending submissions to display</h4>;
            case '/assigned-submissions':
              return protocols?.filter(protocol => protocol.status === "assigned").length > 0 ? <AssignedProtocols protocols={protocols.filter(protocol => protocol.status === "assigned")} /> : <h4>No assigned submissions to display</h4>;
            case '/declined-submissions':
              return protocols?.filter(protocol => protocol.status === "declined").length > 0 ? <DeclinedProtocols protocols={protocols.filter(protocol => protocol.status === "declined")} reviewers={reviewers} handleAssignReviewers={assignReviewers} message={message} /> : <h4>No declined submissions to display</h4>;
            case '/reviewed-submissions':
              return protocols?.filter(protocol => protocol.status === "reviewed" || protocol.status === "partially reviewed").length > 0 ? <ReviewedProtocols protocols={protocols.filter(protocol => protocol.status === "reviewed" || protocol.status === "partially reviewed")} /> : <h4>No reviewed submissions to display</h4>;
            case '/accepted-submissions':
              return protocols?.filter(protocol => protocol.status === "accepted").length > 0 ? <AcceptedProtocols protocols={protocols.filter(protocol => protocol.status === "accepted")} onDelete={deleteProtocol} /> : <h4>No accepted submissions to display</h4>;
            case '/rejected-submissions':
              return protocols?.filter(protocol => protocol.status === "rejected").length > 0 ? <RejectedProtocols protocols={protocols.filter(protocol => protocol.status === "rejected")} onDelete={deleteProtocol} /> : <h4>No rejected submissions to display</h4>;
            case '/responded-submissions':
              return protocols?.filter(protocol => protocol.status === "response").length > 0 ? <RespondedProtocols protocols={protocols.filter(protocol => protocol.status === "response")} handleAssignReviewers={assignReviewers} /> : <h4>No responded submissions to display</h4>;
            default:
              return protocols.length > 0 ? <Protocols protocols={protocols} reviewers={reviewers} handleAssignReviewers={assignReviewers} onDelete={deleteProtocol} /> : <h4>No submissions to display</h4>;
          }
        })()
      }

    </>
  );
}

export default Dashboard;