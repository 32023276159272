import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../AuthContext';
import AttachDocuments from "./studyapplication/AttachDocuments";
import ResearchTeam from "./studyapplication/ResearchTeam";
import ResearchCoordinator from './studyapplication/ResearchCoordinator';
import ProposedResearch from "./studyapplication/ProposedResearch";
import CollaboratingInstitutions from "./studyapplication/CollaboratingInstitutions";
import Population from "./studyapplication/Population";
import RiskDetermination from "./studyapplication/RiskDetermination";
import Training from "./studyapplication/Training";
import ConflictOfInterest from "./studyapplication/ConflictOfInterest";
import StatisticalPlanning from "./studyapplication/StatisticalPlanning";
import ConsentProcess from "./studyapplication/ConsentProcess";
import ClinicalTrials from "./studyapplication/ClinicalTrials";
import Submission from "./studyapplication/Submission";
import Approval from './studyapplication/Approval';
import { CLINICAL_TRIALS, REFRESH_TOKEN, CONFLICTS, CONSENT_PROCESS, COORDINATORS, DOCUMENTS, INSTITUTIONS, INVESTIGATOR, POPULATION, PROPOSED_RESEARCH, PROTOCOL_DRUGS, PROTOCOL_TRAINING, PROTOCOLS, RESEARCH_TEAMS, RISK_DETERMINATION, STATISTICAL_PLANNING, STUDY_TYPE } from '../Constants';


const NewStudyApplication = () => {
  const { state, checkAuthenticated, handleLogout } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [isTokenRefreshing, setIsTokenRefreshing] = useState(false);
  const [number, setNumber] = useState("");
  const [title, setTitle] = useState("");
  const [study_type, setStudyType] = useState("");
  const [conflict_of_interest, setConflictOfInterest] = useState("");
  const [potential_conflict_management_plan, setPotentialConflictManagementPlan] = useState(null);
  const [research_team, setResearchTeam] = useState({});
  const [co_investigators, setCoInvestigators] = useState({});
  const [research_coordinator, setResearchCoordinator] = useState({});
  const [docs, setDocs] = useState({});
  const [proposedResearch, setProposedResearch] = useState({});
  const [collabos, setCollabos] = useState([]);
  const [population, setPopulation] = useState({});
  const [study, setStudy] = useState({});
  const [risk_determination, setRiskDetermination] = useState({});
  const [training, setTraining] = useState({});
  const [stats, setStats] = useState({});
  const [consent, setConsent] = useState({});
  const [clinical, setClinical] = useState({});
  const [drugs, setDrugs] = useState([]);


  useEffect(() => {
    checkAuthenticated();
  }, []);

  // Get Study Type
  const getStudyType = (study_type) => {
    setStudyType(study_type);
  };

  // Get Title
  const getTitle = (title) => {
    setTitle(title);
  };


  // Submit protocol
  const addProtocol = async (study_type, title) => {
    const user = state.user.id;
    const protocol = { user, study_type, title }

    // Send a request with the current access token
    const response = await fetch(`${PROTOCOLS}/`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
      body: JSON.stringify(protocol)
    });

    if (response.status === 401 || response.status === 403 || response.status === 500) {
      // If the server responds with a 401 (Unauthorized) status code,
      // the access token has expired, so we need to refresh the token.
      setIsTokenRefreshing(true);

      // Send a request to the server to refresh the access token
      const refreshResponse = await fetch(REFRESH_TOKEN, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          refresh: localStorage.getItem('refresh')
        })
      });

      if (refreshResponse.ok) {
        // If the refresh request is successful, update the access token and
        // retry the original request with the new access token.
        const tokens = await refreshResponse.json();
        localStorage.setItem('access', tokens.access);
        setIsTokenRefreshing(false);
        return addProtocol(study_type, title);
      } else {
        // If the refresh request fails, the user needs to log in again.
        setIsTokenRefreshing(false);
        handleLogout();
      }
    } else {
      // If the request succeeds, return the response data.
      const data = await response.json();
      console.log(data);
      setNumber(data.number);

      addResearchTeam(data.id, research_team);
      addCoInvestigators(data.id, co_investigators);
      addResearchCoordinator(data.id, research_coordinator);
      addDocuments(data.id, docs);
      addProposedResearch(data.id, proposedResearch);
      addCollabos(data.id, collabos);
      addPopulation(data.id, population);
      addStudy(data.id, study);
      addRiskDetermination(data.id, risk_determination);
      addConflict(data.id, conflict_of_interest, potential_conflict_management_plan);
      addTraining(data.id, training);
      addStats(data.id, stats);
      addConsent(data.id, consent);
      addDrugs(data.id, drugs);
      addClinical(data.id, clinical);
      // return data
    }
  };

  // Get Research Team
  const getResearchTeam = (research_team) => {
    setResearchTeam(research_team);
    handleNext();
  };

  // Post Research Team
  const addResearchTeam = async (protocol, research_team) => {
    const updated_research_team = { ...research_team, protocol: protocol }
    console.log(updated_research_team)
    // Send a request with the current access token
    fetch(RESEARCH_TEAMS, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
      body: JSON.stringify(updated_research_team)
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.log(error))
  };

  // Get Co-Investigators
  const getCoInvestigators = (co_investigators) => {
    setCoInvestigators(co_investigators);
  };

  // Post Co-Investigators
  const addCoInvestigators = async (protocol, co_investigators) => {
    for (const investigator of co_investigators) {
      try {
        const updated_investigator = { ...investigator, protocol: protocol };
        console.log(updated_investigator);
        fetch(
          INVESTIGATOR,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify(updated_investigator),
          }
        )
      } catch (error) {
        console.error(error);
      }
    }
  };


  // Get Research Coordinator
  const getResearchCoordinator = (research_coordinator) => {
    setResearchCoordinator(research_coordinator);
    handleNext();
  };

  // Post Research Coordinator
  const addResearchCoordinator = async (protocol, research_coordinator) => {
    const updated_research_coordinator = { ...research_coordinator, protocol: protocol }
    console.log(updated_research_coordinator)
    // Send a request with the current access token
    fetch(COORDINATORS, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
      body: JSON.stringify(updated_research_coordinator)
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.log(error))
  };

  // Get Attached Documents
  const getDocs = (docs) => {
    setDocs(docs);
    handleNext();
  };

  // Post Attached Documents
  const addDocuments = async (protocol, docs) => {
    const formData = new FormData();
    formData.append('protocol', protocol);
    Object.keys(docs).forEach((key) => {
      if (docs[key]) {
        formData.append(key, docs[key]);
      }
    });
    fetch(DOCUMENTS, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
      body: formData,
    })
      .then(response => response.json())
      .then(data => console.log(data))
  };

  // Get Proposed Research
  const getProposedResearch = (research) => {
    setProposedResearch(research);
    handleNext();
  };

  // Post proposed Research
  const addProposedResearch = async (protocol, research) => {
    const updated_research = { ...research, protocol: protocol }
    console.log(updated_research)
    // Send a request with the current access token
    fetch(PROPOSED_RESEARCH, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
      body: JSON.stringify(updated_research)
    })
  };

  // Get Collaboration Institutions
  const getCollabos = (collabos) => {
    setCollabos(collabos);
    handleNext();
  };

  // Post Collaboration Institutions
  const addCollabos = async (protocol, collabos) => {
    for (const collabo of collabos) {
      try {
        const updated_collabo = { ...collabo, protocol: protocol };
        console.log(updated_collabo);
        fetch(
          INSTITUTIONS,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify(updated_collabo),
          }
        )
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Get Population
  const getPopulation = (population) => {
    setPopulation(population);
  };

  // Post Population
  const addPopulation = async (protocol, population) => {
    const updated_population = { ...population, protocol: protocol }
    console.log(updated_population)
    // Send a request with the current access token
    fetch(POPULATION, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
      body: JSON.stringify(updated_population)
    })
  };

  // Get Study
  const getStudy = (study) => {
    setStudy(study);
    handleNext();
  };

  // Post Study
  const addStudy = async (protocol, study) => {
    const updated_study = { ...study, protocol: protocol }
    console.log(updated_study)
    // Send a request with the current access token
    fetch(STUDY_TYPE, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
      body: JSON.stringify(updated_study)
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.log(error))
  };

  // Get Risk Determination
  const getRiskDetermination = (risk_determination) => {
    setRiskDetermination(risk_determination);
    handleNext();
  };

  // Post Risk Determination
  const addRiskDetermination = async (protocol, risk_determination) => {
    const updated_risk_determination = { ...risk_determination, protocol: protocol }
    console.log(updated_risk_determination)
    // Send a request with the current access token
    fetch(RISK_DETERMINATION, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
      body: JSON.stringify(updated_risk_determination)
    })
  };

  // Get Conflict
  const getConflict = (conflict_of_interest, potential_conflict_management_plan) => {
    setConflictOfInterest(conflict_of_interest);
    setPotentialConflictManagementPlan(potential_conflict_management_plan);
    handleNext();
  };

  // Post Conflict
  const addConflict = async (protocol, conflict_of_interest, potential_conflict_management_plan) => {
    const conflict = new FormData();
    conflict.append("protocol", protocol);
    conflict.append("conflict_of_interest", conflict_of_interest);
    conflict_of_interest && conflict.append("potential_conflict_management_plan", potential_conflict_management_plan);

    // Send a request with the current access token
    fetch(CONFLICTS, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
      body: conflict
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.log(error))
  };

  // Get Training
  const getTraining = (training) => {
    setTraining(training);
    handleNext();
  };

  // Post Training
  const addTraining = async (protocol, training) => {
    const updated_training = { ...training, protocol: protocol }
    console.log(updated_training)
    // Send a request with the current access token
    fetch(PROTOCOL_TRAINING, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
      body: JSON.stringify(updated_training)
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.log(error))
  };


  // Get Consent Process
  const getConsent = (consent) => {
    setConsent(consent);
    handleNext();
  };

  // Post Consent Process
  const addConsent = async (protocol, consent) => {
    const updated_consent = { ...consent, protocol: protocol }
    console.log(updated_consent)
    // Send a request with the current access token
    fetch(CONSENT_PROCESS, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
      body: JSON.stringify(updated_consent)
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.log(error))
  };

  // Get Statistical Planning
  const getStats = (stats) => {
    setStats(stats);
    handleNext();
  };

  // Post Statistical Planning
  const addStats = async (protocol, stats) => {
    const updated_stats = { ...stats, protocol: protocol }
    console.log(updated_stats)
    // Send a request with the current access token
    fetch(STATISTICAL_PLANNING, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
      body: JSON.stringify(updated_stats)
    })
  };

  // Get Clinical Trials
  const getClinical = (clinical) => {
    setClinical(clinical);
    handleNext();
  };


  // Post Clinical Trials
  const addClinical = async (protocol, clinical) => {
    const updated_clinical = { ...clinical, protocol: protocol }
    console.log(updated_clinical)
    // Send a request with the current access token
    fetch(CLINICAL_TRIALS, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access')}`,
      },
      body: JSON.stringify(updated_clinical)
    })
      .then(response => response.json())
      .then(data => handleNext())
  };


  // Get Drugs
  const getDrugs = (drugs) => {
    setDrugs(drugs);
  };


  // Post Drugss
  const addDrugs = async (protocol, drugs) => {
    for (const drug of drugs) {
      try {
        const updated_drug = { ...drug, protocol: protocol };
        fetch(
          PROTOCOL_DRUGS,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify(updated_drug),
          }
        )
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  }

  const handleSubmit = () => {
    addProtocol(study_type, title, conflict_of_interest, potential_conflict_management_plan);
    console.log("Submit");
  };

  return (
    <div>
      {step === 1 && (
        <ResearchTeam
          onGetStudyType={getStudyType}
          onGetResearchTeam={getResearchTeam}
          onGetCoInvestigators={getCoInvestigators}
          onPrev={handlePrev}
        />
      )}
      {step === 2 && (
        <AttachDocuments
          onGetDocs={getDocs}
          onPrev={handlePrev}
          study_type={study_type}
        />
      )}
      {step === 3 && (
        <ResearchCoordinator
          onGetCoordinator={getResearchCoordinator}
          onPrev={handlePrev}
        />
      )}
      {step === 4 && (
        <ProposedResearch
          onGetProposedResearch={getProposedResearch}
          onGetTitle={getTitle}
          onPrev={handlePrev}
        />
      )}
      {step === 5 && (
        <CollaboratingInstitutions
          onGetCollab={getCollabos}
          onPrev={handlePrev}
        />
      )}
      {step === 6 && (
        <Population
          onGetPopulation={getPopulation}
          onGetStudy={getStudy}
          onPrev={handlePrev}
        />
      )}
      {step === 7 && (
        <RiskDetermination
          onGetRiskDetermination={getRiskDetermination}
          onPrev={handlePrev}
        />
      )}
      {step === 8 && (
        <Training
          onGetTraining={getTraining}
          onPrev={handlePrev}
        />
      )}
      {step === 9 && (
        <ConflictOfInterest
          onPrev={handlePrev}
          onGetConflict={getConflict}
        />
      )}
      {step === 10 && (
        <StatisticalPlanning
          onGetStats={getStats}
          onPrev={handlePrev}
          onNext={handleNext}
        />
      )}
      {step === 11 && (
        <ConsentProcess
          onGetConsent={getConsent}
          onPrev={handlePrev}
        />
      )}
      {step === 12 && (
        <ClinicalTrials
          onGetClinical={getClinical}
          onGetDrugs={getDrugs}
          onPrev={handlePrev}
          onNext={handleNext}
        />
      )}
      {step === 13 && (
        <Submission
          onPrev={handlePrev}
          onSubmit={handleSubmit}
        />
      )}
      {step === 14 && (
        <Approval
          number={number}
        />
      )}
    </div>
  );
}

export default NewStudyApplication;