import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../AuthContext';
import EditProfile from "./EditProfile";
import ResetPassword from "./ResetPassword";
import { ACCOUNTS, REFRESH_TOKEN } from "../Constants";

const Profile = () => {
    const { state, checkAuthenticated, handleLogout } = useContext(AuthContext);
    const [isTokenRefreshing, setIsTokenRefreshing] = useState(false);
    const navigate = useNavigate();

    // Check for authentication when the component mounts
    useEffect(() => {
        checkAuthenticated();
    }, []);

    const handleEditClick = () => {
        navigate('/edit-profile');
    };

    const handleDeleteProfile = async (retryCount = 0) => {
        // Send a request with the current access token
        const response = await fetch(`${ACCOUNTS}/${state.user.id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
        });

        if (response.status === 401 || response.status === 403) {
            if (retryCount < 3) {
                // If the server responds with a 401 (Unauthorized) or 403 (Forbidden) status code,
                // the access token has expired, so we need to refresh the token.
                setIsTokenRefreshing(true);

                // Send a request to the server to refresh the access token
                const refreshResponse = await fetch(REFRESH_TOKEN, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        refresh: localStorage.getItem('refresh')
                    })
                });

                if (refreshResponse.ok) {
                    // If the refresh request is successful, update the access token and
                    // retry the original request with the new access token.
                    const tokens = await refreshResponse.json();
                    localStorage.setItem('access', tokens.access);
                    setIsTokenRefreshing(false);
                    return handleDeleteProfile(retryCount + 1);
                }
            }

            // If the refresh request fails or the maximum retry count is reached,
            // the user needs to log in again.
            setIsTokenRefreshing(false);
            handleLogout();
        } else {
            // If the request succeeds, return the response data.
            console.log(response.status);

            if (response.status === 204) {
                alert("Account successfully deleted!");
                handleLogout();
            } else {
                alert("Account unsuccessfully deleted. Please try again.");
            }

        }
    };


    return (
        <>
            <h1 className="my-4">
                <span>Personal Details</span>
            </h1>
            <div className="card shadow p-3 mb-5 bg-white rounded">
                <table className="table">
                    <thead className="thead-light">
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td className="fw-medium">First Name</td>
                            <td>
                                {state.user.first_name}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="fw-medium">Last Name</td>
                            <td>
                                {state.user.last_name}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="fw-medium">Email Address</td>
                            <td>
                                {state.user.email}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="fw-medium">Mobile Number</td>
                            <td>
                                {state.user.mobile}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="fw-medium">Telephone Number</td>
                            <td>
                                {state.user.telephone}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="fw-medium">Physical Address</td>
                            <td>
                                {state.user.address}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-between">
                <button type="button" className="btn btn-primary btn-lg py-2 rounded-1 me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop-edit">
                    Edit profile
                </button>
                <button type="button" className="btn btn-primary btn-lg py-2 rounded-1 me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop-password">
                    Change password
                </button>
                <button type="button" className="btn btn-danger btn-lg py-2 rounded-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop-delete">
                    Delete account
                </button>
            </div>
            <div className="modal fade" id="staticBackdrop-edit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel-edit" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Edit Profile</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <EditProfile />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="staticBackdrop-password" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel-password" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Change Password</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <ResetPassword />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="staticBackdrop-delete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel-delete" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Delete Account</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this account?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary rounded-1" onClick={handleDeleteProfile}>Yes</button>
                            <button type="button" className="btn btn-secondary rounded-1" data-bs-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile;