const DOMAIN = "https://app.mrcz.org.zw";
export const LOGIN = DOMAIN + "/";
export const TOKEN = DOMAIN + "/api/token/";
export const ACCOUNTS = DOMAIN + "/api/accounts";
export const REFRESH_TOKEN = DOMAIN + "/api/token/refresh/";
export const CHANGE_PASSWORD = DOMAIN + "/api/accounts/change-password";

export const PROTOCOL = DOMAIN + "/api/protocols/protocol";
export const PROTOCOLS = DOMAIN + "/api/protocols/protocols";
export const PROTOCOL_COMMENTS = DOMAIN + "/api/protocols/comments/"

export const DOCUMENTS = DOMAIN + "/api/protocols/documents/";
export const CONFLICTS = DOMAIN + "/api/protocols/conflicts/";
export const PROTOCOL_DRUGS = DOMAIN + "/api/protocols/drugs/";
export const POPULATION = DOMAIN + "/api/protocols/population/";
export const STUDY_TYPE = DOMAIN + "/api/protocols/study_type/";
export const PROTOCOL_TRAINING = DOMAIN + "/api/protocols/training/";
export const RESEARCH_TEAMS = DOMAIN + "/api/protocols/research_teams/";
export const INVESTIGATOR = DOMAIN + "/api/protocols/co_investigators/";
export const CLINICAL_TRIALS = DOMAIN + "/api/protocols/clinical_trials/";
export const CONSENT_PROCESS = DOMAIN + "/api/protocols/consent_process/";
export const COORDINATORS = DOMAIN + "/api/protocols/research_coordinators/";
export const RISK_DETERMINATION = DOMAIN + "/api/protocols/risk_determination/";
export const PROPOSED_RESEARCH = DOMAIN + "/api/protocols/proposed_researches/";
export const INSTITUTIONS = DOMAIN + "/api/protocols/collaborating_institutions/";
export const STATISTICAL_PLANNING = DOMAIN + "/api/protocols/statistical_planning/";