import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import responsetocomments from "../../docs/mrcz-201-Response-to-comments-template-1.docx";
import { BsFileWordFill, BsArrowRight } from 'react-icons/bs';
import "./researcher.css";
import ResearchTeam from "./responsetocomments/ResearchTeam";
import ResearchCoordinator from './responsetocomments/ResearchCoordinator';
import AttachDocuments from "./responsetocomments/AttachDocuments";
import ProposedResearch from "./responsetocomments/ProposedResearch";
import CollaboratingInstitutions from "./responsetocomments/CollaboratingInstitutions";
import Population from "./responsetocomments/Population";
import RiskDetermination from "./responsetocomments/RiskDetermination";
import Training from "./responsetocomments/Training";
import ConflictOfInterest from "./responsetocomments/ConflictOfInterest";
import StatisticalPlanning from "./responsetocomments/StatisticalPlanning";
import ConsentProcess from "./responsetocomments/ConsentProcess";
import ClinicalTrials from "./responsetocomments/ClinicalTrials";
import { CLINICAL_TRIALS, CONFLICTS, PROTOCOL, REFRESH_TOKEN, CONSENT_PROCESS, COORDINATORS, DOCUMENTS, INSTITUTIONS, INVESTIGATOR, POPULATION, PROPOSED_RESEARCH, PROTOCOL_COMMENTS, PROTOCOL_DRUGS, PROTOCOL_TRAINING, PROTOCOLS, RESEARCH_TEAMS, RISK_DETERMINATION, STATISTICAL_PLANNING, STUDY_TYPE } from '../Constants';

const ResponseToComments = () => {
    const [response, setResponse] = useState(null);
    const [responseError, setResponseError] = useState("");
    const [cover_letter, setCoverLetter] = useState(null);
    const [coverLetterError, setCoverLetterError] = useState("");
    const { state, checkAuthenticated, handleLogout } = useContext(AuthContext);
    const [isTokenRefreshing, setIsTokenRefreshing] = useState(false);
    const [protocol, setProtocol] = useState({});
    const [protocolToUpdate, setProtocolToUpdate] = useState({});
    const navigate = useNavigate();

    const { id } = useParams();

    // Check for authentication when the component mounts
    useEffect(() => {
        checkAuthenticated();
        getProtocol(id);
        getProtocolToUpdate(id);
    }, []);

    // Get protocol
    const getProtocol = async (id) => {
        const response = await fetch(`${PROTOCOL}/${id}/`, {
            method: 'GET',
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch(REFRESH_TOKEN, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return getProtocol(id);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            const data = await response.json();
            console.log(data);
            setProtocol(data);
        }
    };

    // Get protocol to update
    const getProtocolToUpdate = async (id) => {
        const response = await fetch(`${PROTOCOLS}/${id}/`, {
            method: 'GET',
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch(REFRESH_TOKEN, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return getProtocolToUpdate(id);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            const data = await response.json();
            console.log(data);
            setProtocolToUpdate(data);
        }
    };


    // Update Success Handler
    const handleSuccess = (status) => {
        status === 200 ? alert("Data successfully updated!") : alert("Data unsuccessfully updated. Please try again.");
        getProtocol(id);
    }


    // Update Research Team
    const updateResearchTeam = async (updated_research_team, updated_co_investigators, updated_study_type) => {
        // Send a request with the current access token
        const response = await fetch(`${RESEARCH_TEAMS}/${updated_research_team.id}/`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: JSON.stringify(updated_research_team)
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch(REFRESH_TOKEN, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return updateResearchTeam(updated_research_team, updated_co_investigators, updated_study_type);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);

            updateProtocol(updated_study_type);
            updateCoInvestigators(updated_co_investigators);

            handleSuccess(response.status);
        }
    };

    // Update Protocol
    const updateProtocol = async (data) => {
        const updated_protocol = { ...protocolToUpdate, ...data }
        console.log(updated_protocol);
        fetch(`${PROTOCOLS}/${id}/`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: JSON.stringify(updated_protocol)
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.log(error))
    }

    // Put Co-Investigators
    const updateCoInvestigators = async (updated_investigators) => {
        for (const investigator of updated_investigators) {
            try {
                fetch(
                    `${INVESTIGATOR}/${investigator.id}/`,
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("access")}`,
                        },
                        body: JSON.stringify(investigator),
                    }
                )
            } catch (error) {
                console.error(error);
            }
        }
    };

    // Update Research Coordinator
    const updateResearchCoordinator = async (updated_research_coordinator) => {
        // Send a request with the current access token
        const response = await fetch(`${COORDINATORS}/${updated_research_coordinator.id}/`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: JSON.stringify(updated_research_coordinator)
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch(REFRESH_TOKEN, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return updateResearchCoordinator(updated_research_coordinator);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);

            handleSuccess(response.status);
        }
    };

    // Update Documents 
    const updateDocs = async (updated_docs) => {
        const formData = new FormData();
        Object.keys(updated_docs).forEach((key) => {
            if (updated_docs[key]) {
                formData.append(key, updated_docs[key]);
            }
        });
        // Send a request with the current access token
        const response = await fetch(`${DOCUMENTS}/${updated_docs.id}/`, {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: formData
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch(REFRESH_TOKEN, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return updateDocs(updated_docs);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);

            handleSuccess(response.status);
        }
    };

    // Update Proposed Research 
    const updateProposedResearch = async (updated_proposed_research, updated_title) => {
        // Send a request with the current access token
        const response = await fetch(`${PROPOSED_RESEARCH}/${updated_proposed_research.id}/`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: JSON.stringify(updated_proposed_research)
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch(REFRESH_TOKEN, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return updateProposedResearch(updated_proposed_research, updated_title);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);

            updateProtocol(updated_title);

            handleSuccess(response.status);
        }
    };

    // Update Collaboration Institutions
    const updateCollabos = async (updated_collabos) => {
        for (const updated_collabo of updated_collabos) {
            try {
                const response = await fetch(
                    `${INSTITUTIONS}/${updated_collabo.id}/`,
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("access")}`,
                        },
                        body: JSON.stringify(updated_collabo),
                    }
                );

                if (response.status === 401 || response.status === 403) {
                    // If the server responds with a 401 (Unauthorized) status code,
                    // the access token has expired, so we need to refresh the token.
                    setIsTokenRefreshing(true);

                    // Send a request to the server to refresh the access token
                    const refreshResponse = await fetch(REFRESH_TOKEN, {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            refresh: localStorage.getItem('refresh')
                        })
                    });

                    if (refreshResponse.ok) {
                        // If the refresh request is successful, update the access token and
                        // retry the original request with the new access token.
                        const tokens = await refreshResponse.json();
                        localStorage.setItem('access', tokens.access);
                        setIsTokenRefreshing(false);
                        return updateCollabos(updated_collabos);
                    } else {
                        // If the refresh request fails, the user needs to log in again.
                        setIsTokenRefreshing(false);
                        handleLogout();
                    }
                } else {
                    // If the request succeeds, return the response data.
                    const data = await response.json();
                    console.log(data);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    // Update Study Type
    const updateStudyType = async (updated_study_type) => {
        fetch(`${STUDY_TYPE}/${updated_study_type.id}/`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: JSON.stringify(updated_study_type)
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.log(error))
    }

    // Update Population 
    const updatePopulation = async (updated_population, updated_study) => {
        // Send a request with the current access token
        const response = await fetch(`${POPULATION}/${updated_population.id}/`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: JSON.stringify(updated_population)
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch(REFRESH_TOKEN, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return updatePopulation(updated_population, updated_study);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);

            updateStudyType(updated_study);

            handleSuccess(response.status);
        }
    };

    // Update Risk Determination 
    const updateRiskDetermination = async (updated_risk_determination) => {
        // Send a request with the current access token
        const response = await fetch(`${RISK_DETERMINATION}/${updated_risk_determination.id}/`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: JSON.stringify(updated_risk_determination)
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch(REFRESH_TOKEN, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return updateRiskDetermination(updated_risk_determination);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);

            handleSuccess(response.status);
        }
    };

    // Update Training 
    const updateTraining = async (updated_training) => {
        // Send a request with the current access token
        const response = await fetch(`${PROTOCOL_TRAINING}/${updated_training.id}/`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: JSON.stringify(updated_training)
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch(REFRESH_TOKEN, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return updateTraining(updated_training);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);

            handleSuccess(response.status);
        }
    };

    // Update Conflict of Interest
    const updateConflict = async (conflict_id, conflict_protocol, updated_conflict_of_interest, updated_potential_conflict_management_plan) => {
        const updated_conflict = new FormData();
        updated_conflict.append("id", conflict_id);
        updated_conflict.append("protocol", conflict_protocol);
        updated_conflict.append("conflict_of_interest", updated_conflict_of_interest);
        updated_conflict_of_interest && updated_conflict.append("potential_conflict_management_plan", updated_potential_conflict_management_plan);

        // Send a request with the current access token
        const response = await fetch(`${CONFLICTS}/${conflict_id}/`, {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: updated_conflict
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch(REFRESH_TOKEN, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return updateConflict(conflict_id, conflict_protocol, updated_conflict_of_interest, updated_potential_conflict_management_plan);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);

            handleSuccess(response.status);
        }
    };

    // Update Stats 
    const updateStats = async (updated_stats) => {
        // Send a request with the current access token
        const response = await fetch(`${STATISTICAL_PLANNING}/${updated_stats.id}/`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: JSON.stringify(updated_stats)
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch(REFRESH_TOKEN, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return updateStats(updated_stats);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);

            handleSuccess(response.status);
        }
    };

    // Update Consent Process 
    const updateConsentProcess = async (updated_consent) => {
        // Send a request with the current access token
        const response = await fetch(`${CONSENT_PROCESS}/${updated_consent.id}/`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: JSON.stringify(updated_consent)
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch(REFRESH_TOKEN, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return updateConsentProcess(updated_consent);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);

            handleSuccess(response.status);
        }
    };

    // Update Clinical Trials 
    const updateClinicalTrials = async (updated_clinical_trials, updated_drugs) => {
        // Send a request with the current access token
        const response = await fetch(`${CLINICAL_TRIALS}/${updated_clinical_trials.id}/`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: JSON.stringify(updated_clinical_trials)
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch(REFRESH_TOKEN, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return updateClinicalTrials(updated_clinical_trials);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);

            updateDrugs(updated_drugs);
            handleSuccess(response.status);
        }
    };

    // Update Drugs
    const updateDrugs = async (updated_drugs) => {
        for (const updated_drug of updated_drugs) {
            try {
                fetch(
                    `${PROTOCOL_DRUGS}/${updated_drug.id}/`,
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("access")}`,
                        },
                        body: JSON.stringify(updated_drug),
                    }
                )
            } catch (error) {
                console.error(error);
            }
        }
    };

    // Update Comments
    const updateComments = async (updated_comments) => {
        const formData = new FormData();
        Object.keys(updated_comments).forEach((key) => {
            if (updated_comments[key]) {
                formData.append(key, updated_comments[key]);
            }
        });

        // Send a request with the current access token
        const response = await fetch(`${PROTOCOL_COMMENTS}/${updated_comments.id}/`, {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('access')}`,
            },
            body: formData
        });

        if (response.status === 401 || response.status === 403) {
            // If the server responds with a 401 (Unauthorized) status code,
            // the access token has expired, so we need to refresh the token.
            setIsTokenRefreshing(true);

            // Send a request to the server to refresh the access token
            const refreshResponse = await fetch(REFRESH_TOKEN, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: localStorage.getItem('refresh')
                })
            });

            if (refreshResponse.ok) {
                // If the refresh request is successful, update the access token and
                // retry the original request with the new access token.
                const tokens = await refreshResponse.json();
                localStorage.setItem('access', tokens.access);
                setIsTokenRefreshing(false);
                return updateComments(updated_comments);
            } else {
                // If the refresh request fails, the user needs to log in again.
                setIsTokenRefreshing(false);
                handleLogout();
            }
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);

            if (response.status === 200) {
                alert("Data successfully updated!");
                updateProtocol({ status: "response" });
                navigate("/");
            } else {
                alert("Data unsuccessfully updated. Please try again.");
            }



        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (response === null) {
            setResponseError("Attach the Response to comments");
            return;
        }

        if (cover_letter === null) {
            setCoverLetterError("Attach the Cover Letter");
            return;
        }

        updateComments({ id: protocol?.comments[0].id, protocol: id, response, cover_letter });
    }


    return (
        <>
            <h1 className="mt-4">Response To Comments <i className="bi bi-chevron-right"></i></h1>
            <div className="accordion accordion-flush card shadow p-3 mb-5 bg-white rounded" id="accordionFlushExample">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            Details of Research Team
                        </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            {protocol.research_teams?.map((research, index) => <ResearchTeam key={index} study={protocol.study_type} research={research} investigators={protocol?.co_investigators} onUpdateResearchTeam={updateResearchTeam} />)}
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            Details of Research Coordinator
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            {protocol.research_coordinators?.map((coordinator, index) => <ResearchCoordinator key={index} coordinator={coordinator} onUpdateResearchCoordinator={updateResearchCoordinator} />)}
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            Attached Documents
                        </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            {protocol.documents?.map((document, index) => <AttachDocuments key={index} document={document} onUpdateDocs={updateDocs} study_type={protocol.study_type} />)}
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            Details of Proposed Research
                        </button>
                    </h2>
                    <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            {protocol.proposed_research?.map((research, index) => <ProposedResearch key={index} protocol={protocol} research={research} onUpdateProposedResearch={updateProposedResearch} />)}
                        </div>
                    </div>
                </div>
                {protocol.collaborating_institutions && <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            Contributing Institutions
                        </button>
                    </h2>
                    <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <CollaboratingInstitutions collaborating_institutions={protocol?.collaborating_institutions} onUpdateCollabs={updateCollabos} />
                        </div>
                    </div>
                </div>}
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            Population & Type of Study
                        </button>
                    </h2>
                    <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            {protocol.populations?.map((population, index) => protocol.study_types?.map((study_type) => <Population key={index} population={population} study_type={study_type} onUpdatePopulation={updatePopulation} />))}
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                            Determination of Risk
                        </button>
                    </h2>
                    <div id="flush-collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            {protocol.risk_determinations?.map((risk_determination, index) => <RiskDetermination key={index} risk_determination={risk_determination} onUpdateRiskDetermination={updateRiskDetermination} />)}
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                            Training
                        </button>
                    </h2>
                    <div id="flush-collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            {protocol.trainings?.map((training, index) => <Training key={index} training={training} onUpdateTraining={updateTraining} />)}
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                            Conflict of Interest
                        </button>
                    </h2>
                    <div id="flush-collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            {protocol.conflicts?.map((conflict, index) => <ConflictOfInterest key={index} conflict={conflict} onUpdateConflict={updateConflict} />)}
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                            Statistical Planning & Data Analysis
                        </button>
                    </h2>
                    <div id="flush-collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            {protocol.statistical_plannings?.map((statistical_planning, index) => <StatisticalPlanning key={index} statistical_planning={statistical_planning} onUpdateStats={updateStats} />)}
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                            Consent Process
                        </button>
                    </h2>
                    <div id="flush-collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            {protocol.consent_processes?.map((consent_process, index) => <ConsentProcess key={index} consent_process={consent_process} onUpdateConsent={updateConsentProcess} />)}
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                            Clinical Trials
                        </button>
                    </h2>
                    <div id="flush-collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            {protocol.clinical_trials?.map((clinical_trial, index) => <ClinicalTrials key={index} clinical_trial={clinical_trial} drugs={protocol?.drugs} onUpdateClinical={updateClinicalTrials} />)}
                        </div>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="card shadow p-3 mb-5 bg-white rounded">
                    <div className="d-flex justify-content-center text-primary fw-medium zoom-effect">
                        <a href={responsetocomments} className="fs-5 text-decoration-none me-1" download><BsFileWordFill />Click, fill and submit below<BsArrowRight /></a>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="potential_conflict_management_plan" className="form-label fs-5 fw-medium">Response to comments</label>
                        <input className={`form-control bg-light ${responseError !== "" ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx" id="potential_conflict_management_plan" name="potential_conflict_management_plan" onChange={(event) => {
                            setResponse(event.target.files[0]);
                            setResponseError("");
                        }} />
                        {responseError && <div className="invalid-feedback">{responseError}</div>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="potential_conflict_management_plan" className="form-label fs-5 fw-medium">Cover Letter</label>
                        <input className={`form-control bg-light ${coverLetterError !== "" ? 'is-invalid' : ''}`} type="file" accept=".pdf,.doc,.docx" id="potential_conflict_management_plan" name="potential_conflict_management_plan" onChange={(event) => {
                            setCoverLetter(event.target.files[0]);
                            setCoverLetterError("");
                        }} />
                        {coverLetterError && <div className="invalid-feedback">{coverLetterError}</div>}
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <button type="submit" className="btn btn-primary fw-medium py-2 rounded-1">
                        Submit
                    </button>
                </div>
            </form>
        </>
    );
}

export default ResponseToComments;