import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import { ACCOUNTS, REFRESH_TOKEN } from '../Constants';

const mobileRegex = /^\+\d{1,4}\d{10}$/;

const EditProfile = () => {
    const navigate = useNavigate();

    const { state, checkAuthenticated, handleLogout } = useContext(AuthContext);
    const [isTokenRefreshing, setIsTokenRefreshing] = useState(false);

    const [first_name, setFirstName] = useState(state.user.first_name);
    const [firstNameError, setFirstNameError] = useState("");

    const [last_name, setLastName] = useState(state.user.last_name);
    const [lastNameError, setLastNameError] = useState("");

    const [email, setEmail] = useState(state.user.email);
    const [emailError, setEmailError] = useState("");

    const [mobile, setMobile] = useState(state.user.mobile);
    const [mobileError, setMobileError] = useState("");

    const [telephone, setTelephone] = useState(state.user.telephone);
    const [telephoneError, setTelephoneError] = useState("");

    const [address, setAddress] = useState(state.user.address);
    const [addressError, setAddressError] = useState("");

    const handleInputChange = (event, setText, setTextError) => {
        setText(event.target.value);
        setTextError("");
    }

    // Check for authentication when the component mounts
    useEffect(() => {
        checkAuthenticated();
    }, []);

    const handleEditProfile = async (editUser, retryCount = 0) => {
        const updatedUser = { ...state.user, ...editUser };
        console.log(updatedUser);

        // Send a request with the current access token
        const response = await fetch(`${ACCOUNTS}/${state.user.id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            body: JSON.stringify(updatedUser)
        });

        if (response.status === 401 || response.status === 403) {
            if (retryCount < 3) {
                // If the server responds with a 401 (Unauthorized) or 403 (Forbidden) status code,
                // the access token has expired, so we need to refresh the token.
                setIsTokenRefreshing(true);

                // Send a request to the server to refresh the access token
                const refreshResponse = await fetch(REFRESH_TOKEN, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        refresh: localStorage.getItem('refresh')
                    })
                });

                if (refreshResponse.ok) {
                    // If the refresh request is successful, update the access token and
                    // retry the original request with the new access token.
                    const tokens = await refreshResponse.json();
                    localStorage.setItem('access', tokens.access);
                    setIsTokenRefreshing(false);
                    return handleEditProfile(editUser, retryCount + 1);
                }
            }

            // If the refresh request fails or the maximum retry count is reached,
            // the user needs to log in again.
            setIsTokenRefreshing(false);
            handleLogout();
        } else {
            // If the request succeeds, return the response data.
            const data = await response.json();
            console.log(data);

            if (response.status === 200) {
                alert("Profile successfully updated!");
            } else {
                alert("Profile unsuccessfully updated. Please try again.");
            }

            window.location.reload();

        }
    };


    const handleSubmit = (event) => {
        event.preventDefault();

        if (first_name.trim() === '') {
            setFirstNameError('Blank first name not allowed.');
            return;
        }

        if (last_name.trim() === '') {
            setLastNameError('Blank last name not allowed.');
            return;
        }

        if (email.trim() === '') {
            setEmailError('Blank email address not allowed.');
            return;
        }

        if (mobile.trim() === '') {
            setMobileError('Blank mobile number not allowed.');
            return;
        }

        if (!mobileRegex.test(mobile)) {
            setMobileError('Enter valid mobile number.');
            return;
        }

        if (telephone.trim() !== '' && !mobileRegex.test(telephone)) {
            setTelephoneError('Enter valid telephone number.');
            return;
        }

        if (address.trim() === '') {
            setAddressError('Blank physical address not allowed.');
            return;
        }


        const editUser = { first_name, last_name, email, mobile, telephone, address };

        handleEditProfile(editUser);
    }

    return (
        <form onSubmit={handleSubmit}>
            <table className="table">
                <thead className="thead-light">
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td className="fw-medium">First Name</td>
                        <td>
                            <input type="text" className={`form-control ${firstNameError !== '' ? 'is-invalid' : ''}`} id="first_name" name='first_name' value={first_name} onChange={(event) => handleInputChange(event, setFirstName, setFirstNameError)} />
                            {firstNameError && <div className="invalid-feedback">{firstNameError}</div>}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="fw-medium">Last Name</td>
                        <td>
                            <input type="text" className={`form-control ${lastNameError !== '' ? 'is-invalid' : ''}`} id="last_name" name='last_name' value={last_name} onChange={(event) => handleInputChange(event, setLastName, setLastNameError)} />
                            {lastNameError && <div className="invalid-feedback">{lastNameError}</div>}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="fw-medium">Email Address</td>
                        <td>
                            <input type="text" className={`form-control ${emailError !== '' ? 'is-invalid' : ''}`} id="email" name='email' value={email} onChange={(event) => handleInputChange(event, setEmail, setEmailError)} />
                            {emailError && <div className="invalid-feedback">{emailError}</div>}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="fw-medium">Mobile Number</td>
                        <td>
                            <input type="text" className={`form-control ${mobileError !== '' ? 'is-invalid' : ''}`} id="mobile" name='mobile' value={mobile} onChange={(event) => handleInputChange(event, setMobile, setMobileError)} />
                            {mobileError && <div className="invalid-feedback">{mobileError}</div>}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="fw-medium">Telephone Number</td>
                        <td>
                            <input type="text" className={`form-control ${telephoneError !== '' ? 'is-invalid' : ''}`} id="telephone" name='telephone' value={telephone} onChange={(event) => handleInputChange(event, setTelephone, setTelephoneError)} />
                            {telephoneError && <div className="invalid-feedback">{telephoneError}</div>}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="fw-medium">Physical Address</td>
                        <td>
                            <input type="text" className={`form-control ${addressError !== '' ? 'is-invalid' : ''}`} id="address" name='address' value={address} onChange={(event) => handleInputChange(event, setAddress, setAddressError)} />
                            {addressError && <div className="invalid-feedback">{addressError}</div>}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="modal-footer">
                <button type="submit" className="btn btn-primary rounded-1">Save changes</button>
                <button type="button" className="btn btn-secondary rounded-1" data-bs-dismiss="modal">Close</button>
            </div>
        </form>
    );
}

export default EditProfile;